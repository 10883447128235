import { useEffect } from 'react';

const OmRocker = () => {
  useEffect(() => {
    window.location.replace('https://corporate.rocker.com/sv/om-rocker/');
  }, []);

  return null;
};

export default OmRocker;
